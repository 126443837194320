import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Box, Text } from "@nulogy/components";
import { CODE_WIDTH } from "../../components/CONSTANTS";
import figmaComponents from "./images/designers-guide/figma-components.gif";
import figmaStyles from "./images/designers-guide/figma-styles.gif";
import figmaText from "./images/designers-guide/figma-text.gif";
import figmaGrid from "./images/designers-guide/figma-grid.gif";
import figmaFrame from "./images/designers-guide/figma-frame.gif";
import figmaVersion from "./images/designers-guide/figma-version.gif";
import figmaSaveVersion from "./images/designers-guide/figma-save-version.gif";
import { FIGMA_LINK } from "../../components/CONSTANTS";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Designers' Guide`}</h1>
    <p>{`Learn how to start designing using the Nulogy Design System (NDS).`}</p>
    <h2>{`Tools and assets`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/"
        }}>{`Figma`}</a>{` as our design tool for creating high-fidelity mockups, prototyping, version control, and team collaboration.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://localhost:8000/guides/designers"
        }}>{`IBM Plex`}</a>{` as a Nulogy's sole typeface`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/jRQxfwCL27gfqfrdCxOIQG/NDS"
        }}>{`NDS UI kit`}</a>{` as a library of Figma styles and components`}</li>
      <li parentName="ul">{`This website as a component reference guide`}</li>
    </ul>
    <h2>{`Understanding NDS visual language`}</h2>
    <p>{`To be effective at using the Nulogy Design System designers should familiarize themselves with the basics of NDS visual language. The following articles provide a good starting point:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/design-language/colour"
        }}>{`Colour`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/design-language/spacing"
        }}>{`Spacing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/design-language/typography"
        }}>{`Typography`}</a></li>
    </ul>
    <h2>{`Designing with NDS in Figma`}</h2>
    <h3>{`NDS components`}</h3>
    <p>{`In Figma, the NDS components are available under the `}<inlineCode parentName="p">{`Assets`}</inlineCode>{` panel in Left Sidebar. The components are easily drag-and-dropped into the working frame and further customized through `}<inlineCode parentName="p">{`Layer`}</inlineCode>{` and `}<inlineCode parentName="p">{`Design`}</inlineCode>{` panels.`}</p>
    <Box maxWidth={CODE_WIDTH} m="0 auto" mdxType="Box">
  <img src={figmaComponents} alt="Animation showing how to access NDS components in Figma" />
    </Box>
    <Text textAlign="center" fontSize="small" color="darkGrey" mdxType="Text">
  Using NDS components in Figma
    </Text>
    <h3>{`NDS styles`}</h3>
    <p>{`By using available text, colours, shadows, and grid styles in the `}<inlineCode parentName="p">{`Layer`}</inlineCode>{` panel, designers ensure that their designs stay in line with NDS' visual style.`}</p>
    <Box maxWidth={CODE_WIDTH} m="0 auto" mdxType="Box">
  <img src={figmaStyles} alt="Animation showing how to use NDS colour, and shadow styles in Figma" />
  <Text textAlign="center" fontSize="small" color="darkGrey" mb="6" mdxType="Text">
    Using NDS colour, and shadow styles in Figma
  </Text>
  <img src={figmaText} alt="Animation showing how to use NDS text styles in Figma" />
  <Text textAlign="center" fontSize="small" color="darkGrey" mb="x6" mdxType="Text">
    Using NDS text styles in Figma
  </Text>
  <img src={figmaGrid} alt="Animation showing how to use NDS grid and adjust nudge amount in Figma" />
  <Text textAlign="center" fontSize="small" color="darkGrey" mdxType="Text">
    Using NDS grid styles and adjusting nudge amount in Figma
  </Text>
    </Box>
    <h3>{`Default sreen sizes and Frame component`}</h3>
    <p>{`The NDS Frame component is created to help designers set the working canvas. It comes in different sizes that match breakpoints in NDS. Once imported the Frame component should be detached (Detach Instance) so that other elements can be nested inside.`}</p>
    <Box maxWidth={CODE_WIDTH} m="0 auto" mdxType="Box">
  <img src={figmaFrame} alt="Animation showing how to access NDS components in Figma" />
    </Box>
    <Text textAlign="center" fontSize="small" color="darkGrey" mdxType="Text">
  Using NDS Frame component and setting the working canvas
    </Text>
    <h3>{`Using version history`}</h3>
    <p>{`To leave a record of changes made throughout the design process and to be able to revert to desired revision designs should be regularelly saved to version history. Version history is available in
`}<inlineCode parentName="p">{`File`}</inlineCode>{` menu under `}<inlineCode parentName="p">{`Show Version History`}</inlineCode></p>
    <Box maxWidth={CODE_WIDTH} m="0 auto" mdxType="Box">
  <img src={figmaSaveVersion} alt="Animation showing how to save to version history" />
  <Text textAlign="center" fontSize="small" color="darkGrey" mb="x6" mdxType="Text">
    Saving version history
  </Text>
  <img src={figmaVersion} alt="Animation showing how to view version history" />
  <Text textAlign="center" fontSize="small" color="darkGrey" mdxType="Text">
    Viewing version history
  </Text>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      